/*
フェードインモーダル
/src/css/modules/_modalと併せて利用してください。
スクロールバーによるガタつき制御

--ToDo
モーダル表示時、下のコンテンツを選択できないようにしたい。
フェードイン

*/

class Modal {
    constructor() {
      document.addEventListener('DOMContentLoaded', () => {
        this.modal = document.querySelectorAll('.modal_cont');
        this.modalOpen = document.querySelectorAll('.js_modal');
        this.modalClose = document.querySelectorAll('.js_modal_close');
        this.modalOverlay = document.querySelector('.modal_overlay');
      });
    }
    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.toggleModal();
        });
    }
    toggleModal() {
        let scrollPosition;
        const html = document.documentElement;
        const body = document.body;
        const modalOpenButton = this.modalOpen;
        const modalCloseButton = this.modalClose;

        if(modalOpenButton.length) {
            let target = '';
            modalOpenButton.forEach((button) => {
                button.addEventListener('click', () => {
                    scrollPosition = window.scrollY || document.documentElement.scrollTop;
                    body.classList.add('scrollLock');
                    body.style.top = `${-scrollPosition}px`;
                    if (window.innerHeight < body.scrollHeight) {
                        html.style.overflowY = 'scroll';
                    }
                    target = button.getAttribute('data-modal');
                    modalOpen(target);
                });
            });
            modalCloseButton.forEach((button) => {
                button.addEventListener('click', () => {
                    modalClose(target);
                    target = '';
                    body.classList.remove('scrollLock'); 
                    body.style.top = '';
                    html.style.overflowY = 'auto';
                    window.scrollTo(0, scrollPosition);
                });
            });
        }
        const modalOpen = (target) => {
            Array.from(this.modal).forEach((modal) => {
                const itemData = modal.getAttribute('data-modal_item');
                if(itemData === target) {
                    modalFadeIn(modal);
                }
            });
        }
        const modalClose = (target) => {
            Array.from(this.modal).forEach((modal) => {
                const itemData = modal.getAttribute('data-modal_item');
                if(itemData === target) {
                    modalFadeOut(modal);
                }
            });
        }
        const modalFadeIn = (target) => {
            target.style.display = 'block';
            this.modalOverlay.style.display = 'block';
        }
        const modalFadeOut = (target) => {
            target.style.display = 'none';
            this.modalOverlay.style.display = 'none';
        }
    }
  }
  
  export default Modal;