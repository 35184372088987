import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const fadeUpY = 16;

class Animation {
	constructor() {
		document.addEventListener('DOMContentLoaded', () => {
			this.targetFadeupItems = document.querySelectorAll('.js_an_fadeup_items');
			this.targetFadeIn = document.querySelectorAll('.js_an_fadeIn');
			this.targetFadeUp = document.querySelectorAll('.js_an_fadeUp');
			this.targetBackGround = document.querySelectorAll('.js_fullbg_scroll');
			this.targetBackGroundMobile = document.querySelectorAll('.js_fullbg_scrollMobile');
		});
	}
	init() {
		document.addEventListener('DOMContentLoaded', () => {
			this.fadeIn();
			this.fadeUp();
			this.fadeUpItems();
			this.changeBackGround();
			this.changeBackGroundMobile();
			this.imgPalalax();
		});
	}


	fadeIn() {
		const targets = this.targetFadeIn;		
		if(targets?.length) {
			gsap.utils.toArray(targets).forEach((target) => {
				gsap.from(target, {
					opacity: 0,
					duration: 2,
					ease: "power2.out",
					scrollTrigger: {
						trigger: target,
						start: "top bottom-=100",
					},
				});
			});
		}
	}
	fadeUp() {
		const targets = this.targetFadeUp;
		if(targets?.length) {
			gsap.utils.toArray(targets).forEach((target) => {
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: target,
						start: "top bottom-=100",
					},
				});
				tl.from(target, {
					opacity: 0,
					duration: 1.5,
				});
				tl.from(target, {
					y: fadeUpY,
					duration: 0.75,
					ease: "power2.out",
				}, "");
			});
		}
	}
	fadeUpItems() {
		const targets = this.targetFadeupItems;
		if(targets?.length) {
			targets.forEach((target) => {
				const items = target.children;
				gsap.utils.toArray(items).forEach((item) => {
					const tl = gsap.timeline({
						scrollTrigger: {
							trigger: item,
							start: "top bottom-=100",
						},
					});
					tl.from(item, {
						opacity: 0,
						duration: 1.5,
					});
					tl.from(item, {
						y: fadeUpY,
						duration: 1,
						ease: "power2.out",
					}, "");
				});
			});
		}
	}
	changeBackGround() {
		const fullbgElements = document.querySelectorAll('.js_background_gray_full');
		const targets = this.targetBackGround;
		const body = document.body;
		if(targets?.length) {
		  gsap.utils.toArray(targets).forEach((target) => {
			gsap.from(target, {
				scrollTrigger: {
					trigger: target,
					start: 'top center',   // トリガー開始位置
					end: 'bottom center',  // トリガー終了位置
					onEnter: () => onEnter(),       // トリガーが開始されたら
					onLeave: () => onLeave(),       // トリガーが終了したら
					onEnterBack: () => onEnter(),   // 上にスクロールして戻った場合
					onLeaveBack: () => onLeave(),
				  }
			});
			});

			const onEnter = () => {
			  gsap.to(fullbgElements, {
				duration: 0.3,
				ease: "linear",
				opacity: 1,
			  });
			  body.classList.add(`__isGray`); // セクションごとにユニークなクラスを付与
			};
		
			const onLeave = () => {
			  gsap.to(fullbgElements, {
				duration: 0.6,
				ease: "linear",
				opacity: 0,
			  });
			  body.classList.remove(`__isGray`); // セクションごとのクラスを削除
			};
		}
	  }
	  changeBackGroundMobile() {
		const fullbgElements = document.querySelectorAll('.js_background_gray_full');
		const targets = this.targetBackGroundMobile;
		const body = document.body;
		// スマホサイズの場合のみ処理を行う
		if (targets?.length && window.innerWidth <= 768) {
		
			gsap.utils.toArray(targets).forEach((target) => {
				gsap.from(target, {
					scrollTrigger: {
						trigger: target,
						start: 'top center',   // トリガー開始位置
						end: 'bottom center',  // トリガー終了位置
						onEnter: () => onEnterMobaile(),       // トリガーが開始されたら
						onLeave: () => onLeaveMobaile(),       // トリガーが終了したら
						onEnterBack: () => onEnterMobaile(),   // 上にスクロールして戻った場合
						onLeaveBack: () => onLeaveMobaile(),
					  }
				});
				});
			
				const onEnterMobaile = () => {
					gsap.to(fullbgElements, {
					  duration: 0.3,
					  ease: "linear",
					  opacity: 1,
					
					});
					body.classList.add(`__isGray`); // セクションごとにユニークなクラスを付与
					
				  };
			  
				  const onLeaveMobaile = () => {
					gsap.to(fullbgElements, {
					  duration: 0.6,
					  ease: "linear",
					  opacity: 0,
					});
					body.classList.remove(`__isGray`); // セクションごとのクラスを削除
				  };
		  }
	  }

    // 画像パララックス
    // imgタグ親要素に class="js_top_photo"
    imgPalalax() {

		const t = gsap.utils.toArray(".js_palalax_photo");
        t && t.forEach((e => {
            const t = e.querySelector("img");
            null !== t && gsap.fromTo(t, {
                yPercent: 0
            }, {
                yPercent: -15,
                ease: "none",
                scrollTrigger: {
                    trigger: e,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 3
                }
            })
        }
        ));

    }
}

export default Animation;
