/*
Serviceページ
*/

let mediaSkeleton_elm = `
<div class="js_skeleton e_skeletonMedia">
	<div class="e_skeletonMedia_img"></div>
	<div class="p_case_case_article_item_label">
		<div class="e_skeletonMedia_title"></div>
		<div class="e_skeletonMedia_title"></div>
		<div class="e_skeletonMedia_title"></div>
	</div>
	<div class="p_case_case_article_item_category_list">
		<div class="e_skeletonMedia_category"></div>
		<div class="e_skeletonMedia_category"></div>
		<div class="e_skeletonMedia_category"></div>
	</div>
</div>
`;
let mediaSkeleton = '';
const page_num = 12;
for(let i = 0; i < page_num; i++) {
	mediaSkeleton += mediaSkeleton_elm;
}

class Case {
	constructor() {
		this.currentPage = 1;
		this.serviceSlug = ''; // 必要に応じて設定
		this.scaleId = ''; // 必要に応じて設定
		this.indastryId = ''; // 必要に応じて設定

		document.addEventListener('DOMContentLoaded', () => {
			this.categoryWrap = $('.js_case_category');
		});
	}

	init() {
		document.addEventListener('DOMContentLoaded', () => {
			const flag = document.querySelector('.js_p_case');
			if(flag) {
				this.searchPosts();
				this.clearFilters();
			}
		});
		window.addEventListener('load', () => {
			const flag = document.querySelector('.js_p_case');
			if(flag) {
				this.fetchCategory();
				this.fetchPosts(this.currentPage);
			}
		});
	}
	fetchCategory() {
		const self = this;
		$.ajax({
			url: 'https://media.meo-taisaku.com/wp-json/my-custom/v1/taxonomies',
			// url: 'https://lulls.work/makesuku/wp-json/my-custom/v1/taxonomies',
			method: 'GET',
			dataType: 'json',
			success: function (data) {
				// サービスのチェックボックスを生成
				const serviceContainer = $('.e_search_category_list_list[data-category="service"]');
				if (data.service) {
					data.service.forEach(function (term) {
						const label = $('<label/>', { class: 'e_search_category_listItem' });
						const checkbox = `<input type="checkbox" name="service_slug" value="${term.slug}" class="e_search_category_input">${term.name}</input>`;
						label.html(checkbox);
						serviceContainer.append(label);
					});
				}

				// 業種のチェックボックスを生成
				const industryContainer = $('.e_search_category_list_list[data-category="industry"]');
				if (data.industry) {
					data.industry.forEach(function (term) {
						const label = $('<label/>', { class: 'e_search_category_listItem' });
						const checkbox = `<input type="checkbox" name="indastry_id" value="${term.id}" class="e_search_category_input">${term.name}</input>`;
						label.html(checkbox);
						industryContainer.append(label);
					});
				}

				// 従業員数のチェックボックスを生成
				const employeeCountContainer = $('.e_search_category_list_list[data-category="scale"]');
				if (data.scale) {
					data.scale.forEach(function (term) {
						const label = $('<label/>', { class: 'e_search_category_listItem' });
						const checkbox = `<input type="checkbox" name="scale_id" value="${term.id}" class="e_search_category_input">${term.name}</input>`;
						label.html(checkbox);
						employeeCountContainer.append(label);
					});
				}

				const skeltons = self.categoryWrap.find('.js_skeleton_wrap');
				const hiddenItems = self.categoryWrap.find('.js_skeleton_hidden');
				$(skeltons).remove();
				$(hiddenItems).fadeIn(300);
			},
			error: function (xhr, status, error) {
				console.error('Error fetching taxonomies:', error);
				$('.js_skeleton_wrap').empty(); // コンテナをクリア
				$('.js_case_category_wrap').empty(); // コンテナをクリア
				$('.js_case_category_wrap').append('<p>読み込みに失敗しました。再度ページを読み込んでください。</p>');
			}
		});
	}
	clearFilters() {
		$('.js_case_clear').on('click', () => {
			$('input.e_search_category_input:checkbox').prop('checked', false); // Uncheck all checkboxes
			this.serviceSlug = '';
			this.scaleId = '';
			this.indastryId = '';

			// クリア後に記事読み込みをする場合
			// this.fetchPosts(this.currentPage);
		});
	}
	searchPosts(page) {
		const self = this;
		$('.js_case_search').on('click', function () {
			$('#posts-container').empty(); // コンテナをクリア
			$('#posts-container').append(mediaSkeleton);
			// チェックされているサービス、業種、従業員数の値を取得
			const selectedServices = $('input[name="service_slug"]:checked').map(function () {
					return $(this).val();
			}).get();

			const selectedIndustries = $('input[name="indastry_id"]:checked').map(function () {
					return $(this).val();
			}).get();

			const selectedScales = $('input[name="scale_id"]:checked').map(function () {
					return $(this).val();
			}).get();
			// データをまとめてAPIに送信
			$.ajax({
					url: 'https://media.meo-taisaku.com/wp-json/my-custom/v1/filtered-posts',
					// url: 'https://lulls.work/makesuku/wp-json/my-custom/v1/filtered-posts',
					method: 'GET',
					dataType: 'json',
					data: {
						service_slug: selectedServices,
						indastry_id: selectedIndustries,
						scale_id: selectedScales,
						paged: page,
					},
					success: function (response) {
						// 受け取った記事データを動的に表示
						const resultsContainer = $('#posts-container');
						resultsContainer.empty(); // 既存のコンテンツをクリア
						if (response.posts.length > 0) {
							self.displayPosts(response.posts);
							self.renderPagination(response);
						} else {
							resultsContainer.append('<p>条件に一致する記事はありません。</p>');
							self.renderPagination(response);
						}
					},
					error: function (xhr, status, error) {
							console.error('Error fetching posts:', error);
							$('#posts-container').empty(); // コンテナをクリア
							$('#posts-container').append('<p>読み込みに失敗しました。再度ページを読み込んでください。</p>');
							$('.js_case_pagination').empty();
					}
			});
		});
	}
	fetchPosts(page, scroll=false) {
		const self = this; // `this` を保存
		$('#posts-container').empty(); // コンテナをクリア
		$('#posts-container').append(mediaSkeleton);

		$.ajax({
			url: 'https://media.meo-taisaku.com/wp-json/my-custom/v1/filtered-posts',
			// url: 'https://lulls.work/makesuku/wp-json/my-custom/v1/filtered-posts',
			method: 'GET',
			data: {
				service_slug: this.serviceSlug,
				scale_id: this.scaleId,
				indastry_id: this.indastryId,
				paged: page
			},
			success: function(response) {
				self.displayPosts(response.posts); // 保存した `self` を使用
				self.renderPagination(response); // 保存した `self` を使用
				if(scroll) {
					// スクロールをアニメーションさせる
					$('html, body').animate({ scrollTop: $('#posts-container').offset().top - 200 }, 0);
				}
			},
			error: function(xhr, status, error) {
				console.error('APIエラー:', error);
				$('#posts-container').empty(); // コンテナをクリア
				$('#posts-container').append('<p>読み込みに失敗しました。再度ページを読み込んでください。</p>');
				$('.js_case_pagination').empty();
			}
		});
	}

	// コンテンツ生成
	displayPosts(posts) {
		if (posts.length > 0) {
			$('#posts-container').empty(); // コンテナをクリア
			$.each(posts, (index, post) => { // アロー関数を使用
				$('#posts-container').append(`
					<a href="${post.url}" target="_blank" class="js_skeleton_hidden p_case_case_article_item" style="opacity: 0.5;">
						<div class="p_case_case_article_item_top">
							<div class="p_case_case_article_item_img">
								<img src="${post.thumbnail}" alt="${post.title}">
								<span class="p_case_case_article_item_img_circle"></span>
							</div>
							<p class="p_case_case_article_item_label t_title_large">${post.title}</p>
						</div>
						<div class="p_case_case_article_item_textarea">
							<ul class="p_case_case_article_item_category_list">
								<li class="p_case_case_article_item_category_item t_body_small">
									<span class="p_case_case_article_item_category_label">業種：</span>
									<span class="p_case_case_article_item_category_desc">${post.indastry_name || ''}</span>
								</li>
								<li class="p_case_case_article_item_category_item t_body_small">
									<span class="p_case_case_article_item_category_label">規模：</span>
									<span class="p_case_case_article_item_category_desc">${post.scale_name || ''}</span>
								</li>
								<li class="p_case_case_article_item_category_item t_body_small">
									<span class="p_case_case_article_item_category_label">サービス：</span>
									<span class="p_case_case_article_item_category_desc">${post.service_name || ''}</span>
								</li>
							</ul>
						</div>
					</a>
				`);
			});
			$('#posts-container').children('.js_skeleton_hidden').animate({
				opacity: 1
			}, 300);
		} else {
			$('#posts-container').empty(); // コンテナをクリア
			$('#posts-container').append('<p>記事が見つかりませんでした。</p>');
		}
	}
	renderPagination(posts) {
		const paginationContainer = $('.js_case_pagination');
    paginationContainer.empty(); // ページネーションをクリア
    const currentPage = posts.current_page;
    const totalPages = posts.total_pages;

    // ページ数が1ページのみの時はページネーションを表示しない
    if (totalPages <= 1) return;

    const createPageLink = (page, isCurrent = false) => {
        return isCurrent
            ? `<span class="page-numbers current">${page}</span>`
            : `<a href="#" class="page-numbers" data-page="${page}">${page}</a>`;
    };

    const createDots = () => '<span class="page-numbers dots">…</span>';
    const createPrevLink = (currentPage) => currentPage > 1
        ? `<a class="prev page-numbers" href="#" data-page="${currentPage - 1}"></a>`
        : '';
    const createNextLink = (currentPage, totalPages) => currentPage < totalPages
        ? `<a class="next page-numbers" href="#" data-page="${currentPage + 1}"></a>`
        : '';

    // 「←」リンク
    paginationContainer.append(createPrevLink(currentPage));

    // ページリンクを生成
    if (totalPages === 2) {
        paginationContainer.append(createPageLink(1, currentPage === 1));
        paginationContainer.append(createPageLink(2, currentPage === 2));
    } else if (totalPages === 3) {
        paginationContainer.append(createPageLink(1, currentPage === 1));
        paginationContainer.append(createPageLink(2, currentPage === 2));
        paginationContainer.append(createPageLink(3, currentPage === 3));
    } else if (totalPages === 4) {
        paginationContainer.append(createPageLink(1, currentPage === 1));
        paginationContainer.append(createPageLink(2, currentPage === 2));
        paginationContainer.append(createPageLink(3, currentPage === 3));
        paginationContainer.append(createPageLink(4, currentPage === 4));
    } else if (totalPages >= 5) {
        if (currentPage === 1 || currentPage === 2) {
            paginationContainer.append(createPageLink(1, currentPage === 1));
            paginationContainer.append(createPageLink(2, currentPage === 2));
            paginationContainer.append(createPageLink(3, currentPage === 3));
            paginationContainer.append(createDots());
            paginationContainer.append(createPageLink(totalPages));
        } else if (currentPage === totalPages || currentPage === totalPages - 1) {
            paginationContainer.append(createPageLink(1));
            paginationContainer.append(createDots());
            paginationContainer.append(createPageLink(totalPages - 2, currentPage === totalPages - 2));
            paginationContainer.append(createPageLink(totalPages - 1, currentPage === totalPages - 1));
            paginationContainer.append(createPageLink(totalPages, currentPage === totalPages));
        } else {
            paginationContainer.append(createPageLink(1));
            paginationContainer.append(createDots());
            // paginationContainer.append(createPageLink(currentPage - 1));
            paginationContainer.append(createPageLink(currentPage, true));
            // paginationContainer.append(createPageLink(currentPage + 1));
            paginationContainer.append(createDots());
            paginationContainer.append(createPageLink(totalPages));
        }
    }

    // 「→」リンク
    paginationContainer.append(createNextLink(currentPage, totalPages));

    // ページリンクにクリックイベントを追加
    paginationContainer.find('.page-numbers:not(.dots)').on('click', (event) => {
        event.preventDefault();
        const page = $(event.currentTarget).data('page');
        this.currentPage = page; // 現在のページを更新
				$('html, body').animate({ scrollTop: $('#posts-container').offset().top - 200 }, 0);


        // 選択されたサービス、業種、従業員数の値を取得
        this.serviceSlug = $('input[name="service_slug"]:checked').map(function () {
            return $(this).val();
        }).get();

        this.indastryId = $('input[name="indastry_id"]:checked').map(function () {
            return $(this).val();
        }).get();

        this.scaleId = $('input[name="scale_id"]:checked').map(function () {
            return $(this).val();
        }).get();

        // データをまとめてAPIに送信
        this.fetchPosts(page, scroll=true); // 次のページの記事を取得
    });
	}
}

export default Case;
