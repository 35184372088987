// カスタムフィールドの改行でSPだけ非表示にする実装
class SpNobr {
	init() {
		document.addEventListener('DOMContentLoaded', () => {
			this.setSpNobr();
		});
	}
	setSpNobr() {
		const contentElement = document.querySelector('.ps_service_desc_txt');
		if (contentElement) {
            let contentHTML = contentElement.innerHTML;

            // スマホ表示の時だけ処理を行う
            if (window.innerWidth <= 768) {  // スマホ表示時にのみ実行
                // [[nobr]]と[[/nobr]]で囲まれた部分を探す
                 // [[nobr]] タグの前にある <br> タグを削除する
				 contentHTML = contentHTML.replace(/<br\s*\/?>\s*\[\[nobr\]\]/g, '[[nobr]]');

				 // [[nobr]]と[[/nobr]]で囲まれた部分を探す
				 const regex = /\[\[nobr\]\](.*?)\[\[\/nobr\]\]/gs;
 
				 contentHTML = contentHTML.replace(regex, (match, p1) => {
					 // 一旦、[[nobr]]と[[/nobr]]の間の部分をHTMLとして解析
					 const tempDiv = document.createElement('div');
					 tempDiv.innerHTML = p1;
 
					 // 解析された部分の中の<br>タグを削除する
					 const brElements = tempDiv.querySelectorAll('br');
					 brElements.forEach(br => {
						 br.remove();
					 });
 
					 // [[nobr]]内の改行無効化後のHTMLを返す
					 return tempDiv.innerHTML; // <br> が削除された内容を返す
				 });
 
				 // [[nobr]]と[[/nobr]]タグを削除
				 contentHTML = contentHTML.replace(/\[\[nobr\]\]/g, '').replace(/\[\[\/nobr\]\]/g, '');
 
				 // 修正後のコンテンツを再設定
				 contentElement.innerHTML = contentHTML;
            } else {
                // PC表示時は[[nobr]]と[[/nobr]]タグを削除する
                contentHTML = contentHTML.replace(/\[\[nobr\]\]/g, '').replace(/\[\[\/nobr\]\]/g, '');
                contentElement.innerHTML = contentHTML; // 修正後のコンテンツを再設定
            }
        }
    }
}

export default SpNobr;
