/*
detailsタグを使用したアコーディオン
/src/css/modules/_accordionと併せて利用してください。

--ToDo
アクセシビリティ準拠の追加対応
よく使うスタイルを追加
*/

class Accordion {
    constructor() {
        document.addEventListener("DOMContentLoaded", () => {
            this.details = document.querySelectorAll(".accordion")
        });
    }
    init() {
        document.addEventListener("DOMContentLoaded", () => {
            this.Accordion();
        });
    }
    Accordion() {
        const details = this.details;
        if(details.length) {
            details.forEach((detail) => {
							const summary = detail.querySelector(".accordion_summary");
							const answer = detail.querySelector(".accordion_answer");
							if(detail.getAttribute("open") !== null) {
								summary.classList.add('__isOpen');
							}
							summary.addEventListener("click", (event) => {
								// デフォルトの挙動を無効化
								event.preventDefault();
								// detailsのopen属性を判定
								if (detail.getAttribute("open") !== null) {
										summary.classList.remove('__isOpen');
										// 閉じるときの処理
										const closingAnim = answer.animate(closingAnimation(answer), animTiming);
										closingAnim.onfinish = () => {
												// 完了後にopen属性を取り除く
												detail.removeAttribute("open");
										};
								} else {
										summary.classList.add('__isOpen');
										// open属性を付与
										detail.setAttribute("open", "true");
										// アコーディオンを開くときの処理
										const openingAnim = answer.animate(openingAnimation(answer), animTiming);
								}
							});
            });
        }
        // アニメーションの時間とイージング
        const animTiming = {
            duration: 300,
            easing: "ease-in-out",
        };
        // アコーディオンを閉じるときのキーフレーム
        const closingAnimation = (answer) => [
        {
            height: answer.offsetHeight + "px",
            opacity: 1,
        },
        {
            height: 0,
            opacity: 0,
        },
        ];
        // アコーディオンを開くときのキーフレーム
        const openingAnimation = (answer) => [
        {
            height: 0,
            opacity: 0,
        },
        {
            height: answer.offsetHeight + "px",
            opacity: 1,
        },
        ];
    }
}
export default Accordion;
