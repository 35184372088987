import Components from './components/Components';
import Utils from './utils/Utils';
import Pages from './pages/Pages';

class App {
  constructor() {
    this.utils = new Utils();
    this.components = new Components();
    this.pages = new Pages();
  }
  render() {
    this.utils.init();
    this.components.init();
    this.pages.init();
  }
}

export default App;
