import Roaded from "./Roaded";
import Vw from "./Vw";
import LinkIcon from "./LinkIcon";
import CustomAdmin from "./CustomAdmin";
import SpNobr from "./SpNobr";
class Utils {
  constructor() {
    this.roaded = new Roaded();
    this.vw = new Vw();
    this.linkicon = new LinkIcon();
    this.customAdmin = new CustomAdmin();
    this.spNobr = new SpNobr();
  }
  init() {
    this.roaded.init();
    this.vw.init();
    this.linkicon.init();
    this.customAdmin.init();
    this.spNobr.init();
  }
}
export default Utils;
