/*
Serviceページ
*/

class Service {
	init() {
		document.addEventListener('DOMContentLoaded', () => {
			this.tagSearch();
			this.caseFetch();
			this.ToptagSearch();
		});
	}
	tagSearch() {
		const tagLinks = document.querySelectorAll('button.e_tag_link');

		if (tagLinks) {
		  const serviceItems = document.querySelectorAll('.p_service_item');

		  tagLinks.forEach(button => {
			button.addEventListener('click', function () {
			  // クリックされたタグに "active" クラスをトグル（ON/OFF）する
			  this.classList.toggle('active');

			  // 選択されたタグを取得（"active" クラスがついているもの）
			  const selectedTags = document.querySelectorAll('button.e_tag_link.active');

			  // タグが選択されていない場合は全ての投稿を表示
			  if (selectedTags.length === 0) {
				serviceItems.forEach(item => {
				  item.style.display = 'block';
				});
				return;
			  }

			  // 各投稿ごとにフィルタリング処理
			  serviceItems.forEach(item => {
				const itemTags = item.querySelectorAll('.p_service_item .e_tag_link');
				let hasSelectedTag = false;

				// 選択されたタグと投稿のタグを比較
				selectedTags.forEach(selectedTag => {
				  itemTags.forEach(itemTag => {
					if (itemTag.textContent.trim() === selectedTag.textContent.trim()) {
					  hasSelectedTag = true;
					}
				  });
				});

				// フィルタリングの結果に基づいてアイテムを表示・非表示
				item.style.display = hasSelectedTag ? 'block' : 'none';
			  });
			});
		  });
		}

		 // URLからタグIDを取得
		 const urlParams = new URLSearchParams(window.location.search);
		 const tagId = urlParams.get('tag_id');

		 if (tagId) {
		   const tagButtons = document.querySelectorAll('.e_tag_link');

		   tagButtons.forEach(button => {
			 if (button.dataset.tagId === tagId) {
			   button.classList.add('active');
			 }
		   });

		   filterServices();
		 }

		 // フィルタリング処理の定義
		 function filterServices() {
		   const serviceItems = document.querySelectorAll('.p_service_item');
		   const selectedTags = document.querySelectorAll('button.e_tag_link.active');

		   // タグが選択されていない場合は全ての投稿を表示
		   if (selectedTags.length === 0) {
			 serviceItems.forEach(item => {
			   item.style.display = 'block';
			 });
			 return;
		   }

		   // 各投稿ごとにフィルタリング処理
		   serviceItems.forEach(item => {
			 const itemTags = item.querySelectorAll('.p_service_item .e_tag_link');
			 let hasSelectedTag = false;

			 // 選択されたタグと投稿のタグを比較
			 selectedTags.forEach(selectedTag => {
			   itemTags.forEach(itemTag => {
				 if (itemTag.textContent.trim() === selectedTag.textContent.trim()) {
				   hasSelectedTag = true;
				 }
			   });
			 });

			 // フィルタリングの結果に基づいてアイテムを表示・非表示
			 item.style.display = hasSelectedTag ? 'block' : 'none';
		   });
		 }

	}
	ToptagSearch() {
		const ToptagButtons = document.querySelectorAll('a.e_tag_link');

		ToptagButtons.forEach(button => {
		  button.addEventListener('click', function (event) {
			event.preventDefault(); // デフォルトのリンク動作をキャンセル
			const tagId = this.dataset.tagId;
			const url = `/service?tag_id=${tagId}`; // 遷移先URLを指定
			window.location.href = url; // 新しいURLに遷移
		  });
		});
		// ページ読み込み後にそれぞれの関数を呼び出す
		document.addEventListener('DOMContentLoaded', () => {
			if (document.body.classList.contains('top-page')) {
			  ToptagSearch();
			} else if (document.body.classList.contains('service-list-page')) {
			  tagSearch();
			}
		  });
	}
	caseFetch() {
		let currentUrl = window.location.pathname;
		let service_name = currentUrl.split('/').filter(Boolean).pop();
		// console.log(service_name);
		const target = document.querySelector('.js_service_caseFetch');
		if(target) {
			$.ajax({
				url: 'https://media.meo-taisaku.com/wp-json/my-custom/v1/filtered-posts',
				// url: 'https://lulls.work/makesuku/wp-json/my-custom/v1/filtered-posts',
				method: 'GET',
				data: {
					service_slug: service_name,
				},
				success: function(response) {
					const posts = response.posts;
					setTimeout(()=> {
						if (posts.length > 0) {
							$('.js_service_caseFetch_container').empty(); // コンテナをクリア
							let count = 0;
							$.each(posts, (index, post) => { // アロー関数を使用
								count++;
								if(count <= 3) {
									$('.js_service_caseFetch .js_service_caseFetch_container').append(`
										<a href="${post.url}" target="_blank" class="js_skeleton_hidden p_case_case_article_item" style="opacity: 0.25;">
											<div class="p_case_case_article_item_top">
												<div class="p_case_case_article_item_img">
													<img src="${post.thumbnail}" alt="${post.title}">
													<span class="p_case_case_article_item_img_circle"></span>
												</div>
												<p class="p_case_case_article_item_label t_title_large">${post.title}</p>
											</div>
											<div class="p_case_case_article_item_textarea">
												<ul class="p_case_case_article_item_category_list">
													<li class="p_case_case_article_item_category_item t_body_small">
														<span class="p_case_case_article_item_category_label">業種：</span>
														<span class="p_case_case_article_item_category_desc">${post.indastry_name || ''}</span>
													</li>
													<li class="p_case_case_article_item_category_item t_body_small">
														<span class="p_case_case_article_item_category_label">規模：</span>
														<span class="p_case_case_article_item_category_desc">${post.scale_name || ''}</span>
													</li>
													<li class="p_case_case_article_item_category_item t_body_small">
														<span class="p_case_case_article_item_category_label">サービス：</span>
														<span class="p_case_case_article_item_category_desc">${post.service_name || ''}</span>
													</li>
												</ul>
											</div>
										</a>
									`);
								}
							});
							$('.js_service_caseFetch_container').children('.js_skeleton_hidden').animate({
								opacity: 1
							}, 300);
						} else {
							console.log("count" + 0)
							target.remove();
						}
					},300);
				},
				error: function(xhr, status, error) {
					console.error('APIエラー:', error);
					$('.js_service_caseFetch_container').empty(); // コンテナをクリア
					$('.js_service_caseFetch_container').append('<p>読み込みに失敗しました。再度ページを読み込んでください。</p>');
				}
			});
		}
	}
}

export default Service;
