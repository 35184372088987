class CustomAdmin {
	init() {
		document.addEventListener('DOMContentLoaded', () => {
			this.ImageUpload();
			this.MaxTag();
			this.OtherPost();
			this.SpKvUp();
		});
	}
	ImageUpload() {
        
        jQuery(document).ready(function($) {
            var frame;
        
            $('.upload_images_button').on('click', function(e) {
                e.preventDefault();
        
                var $button = $(this);
                var fieldId = $button.data('field-id'); // フィールドIDを取得
                var $inputField = $('#' + fieldId); // 対応するhidden input
                var $imagePreviews = $('.image_previews[data-field-id="' + fieldId + '"]'); // 対応する画像プレビュー
        
                // 既存のフレームを再利用するか、新しいものを開く
                if (frame) {
                    frame.open();
                    return;
                }
        
                // メディアアップローダーの初期化
                frame = wp.media({
                    title: '画像を選択',
                    button: {
                        text: '画像を追加'
                    },
                    multiple: false // 単一画像選択
                });
        
                // 画像が選択された時の処理
                frame.on('select', function() {
                    var attachment = frame.state().get('selection').first().toJSON();
                    var imageUrl = attachment.url;
        
                    // 選択した画像URLを対応するhidden inputにセット
                    $inputField.val(imageUrl);
        
                    // 対応する画像プレビューに表示
                    $imagePreviews.html('<div class="image-preview"><img src="' + imageUrl + '" style="max-width: 200px; margin-top: 10px;" /></div>');
                });
        
                frame.open(); // メディアアップローダーを開く
            });
        });
  
        jQuery(document).ready(function($) {
            var frame;
        
            $('.upload_images_button2').on('click', function(e) {
                e.preventDefault();
        
                var $button = $(this);
                var fieldId = $button.data('field-id'); // フィールドIDを取得
                var $inputField = $('#' + fieldId); // 対応するhidden input
                var $imagePreviews = $('.image_previews[data-field-id="' + fieldId + '"]'); // 対応する画像プレビュー
        
                // 既存のフレームを再利用するか、新しいものを開く
                if (frame) {
                    frame.open();
                    return;
                }
        
                // メディアアップローダーの初期化
                frame = wp.media({
                    title: '画像を選択',
                    button: {
                        text: '画像を追加'
                    },
                    multiple: false // 単一画像選択
                });
        
                // 画像が選択された時の処理
                frame.on('select', function() {
                    var attachment = frame.state().get('selection').first().toJSON();
                    var imageUrl = attachment.url;
        
                    // 選択した画像URLを対応するhidden inputにセット
                    $inputField.val(imageUrl);
        
                    // 対応する画像プレビューに表示
                    $imagePreviews.html('<div class="image-preview"><img src="' + imageUrl + '" style="max-width: 200px; margin-top: 10px;" /></div>');
                });
        
                frame.open(); // メディアアップローダーを開く
            });
        });
        
	}

    MaxTag() {
        jQuery(document).ready(function($) {
            var maxTags = 4;
            var errorMessage = $('<div class="tag-error-message" style="color: red; margin-top: 10px;">最大3つまでタグを追加できます。</div>');
            var tagInputField = $('#new-tag-post_tag'); // タグの入力フィールド
            var tagChecklist = $('#tagsdiv-post_tag .tagchecklist'); // 追加済みのタグリスト
    
            // タグリストが存在するかチェック
            if (tagChecklist.length > 0) {
                // エラーメッセージのクリア
                function clearErrorMessage() {
                    $('.tag-error-message').remove();
                }
    
                // タグ数をチェックしてエラーメッセージを表示
                function checkTagCount() {
                    var currentTags = tagChecklist.find('span.remove-tag-icon').length;
                    if (currentTags >= maxTags) {
                        clearErrorMessage();
                        tagInputField.after(errorMessage); 
                        tagInputField.attr('disabled', 'disabled');
                    } else {
                        clearErrorMessage();
                        tagInputField.removeAttr('disabled');
                    }
                }
    
                // MutationObserverを使用してタグの変更を監視
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        if (mutation.type === 'childList') {
                            checkTagCount(); 
                        }
                    });
                });
    
                // タグリスト要素を監視対象として設定
                observer.observe(tagChecklist[0], { childList: true });
    
                // 初期ロード時にチェック
                checkTagCount();
    
                // 投稿が保存される前にタグ数を再チェック
                $('#post').on('submit', function(e) {
                    var currentTags = tagChecklist.find('span.remove-tag-icon').length;
                    if (currentTags >= maxTags) {
                        alert('最大3つまでタグを追加できます。');
                        e.preventDefault();
                    }
                });
            } else {
            }
        });
    }
    
    OtherPost() {
        document.addEventListener('DOMContentLoaded', function() {
            console.log(OtherPost);
            const checkboxes = document.querySelectorAll('#related_posts_checkboxes input[type="checkbox"]');
            const countDisplay = document.getElementById('checkbox_count small');

            function updateCount() {
                const checkedCount = document.querySelectorAll('#related_posts_checkboxes input[type="checkbox"]:checked').length;
                countDisplay.textContent = '選択数: ' + checkedCount + '/5';

                // 5件以上選択できないようにする
                checkboxes.forEach(checkbox => {
                    if (checkedCount >= 5 && !checkbox.checked) {
                        checkbox.disabled = true;
                    } else {
                        checkbox.disabled = false;
                    }
                });
            }

            checkboxes.forEach(checkbox => {
                checkbox.addEventListener('change', updateCount);
            });

            // 初期化
            updateCount();
        });
    }
    
    SpKvUp() {
        var mediaUploader;
        const mediaUploaderButton = document.getElementById('add_second_image_button');
        console.log(mediaUploaderButton);
        $(mediaUploaderButton).click(function(e) {
            e.preventDefault();
            if (mediaUploader) {
                mediaUploader.open();
                return;
            }
            mediaUploader = wp.media.frames.file_frame = wp.media({
                title: '2つ目のアイキャッチ画像を選択',
                button: { text: '画像を選択' },
                multiple: false
            });
            mediaUploader.on('select', function() {
                var attachment = mediaUploader.state().get('selection').first().toJSON();
                $('#second_featured_image_id').val(attachment.id);
                $('#second_featured_image_preview').html('<img src="' + attachment.url + '" style="max-width:100%;" />');  // プレビュー表示
            });
            mediaUploader.open();
        });
    
        $('#remove_second_image_button').click(function(e) {
            e.preventDefault();
            $('#second_featured_image_id').val('');
            $('#second_featured_image_preview').html('');  // プレビューもクリア
        });
        
        jQuery(document).ready(function($) {
        });
        
    }
}

export default CustomAdmin;