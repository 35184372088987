import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class SideBar {
    init() {
		document.addEventListener('DOMContentLoaded', () => {
			this.scrollSlider();
			this.headingActive();
		});
	}

    scrollSlider() {
      var sidebar = document.querySelector('.side_inner');
      if (sidebar) {
        var parentElement = sidebar.parentElement; // サイドバーの親要素を取得
        var firstViewHeight = document.querySelector('.subpage_fv01').offsetHeight;
        var imgSlideCtaWrap = document.querySelector('.e_imgSlideCta_wrap'); // 目標の要素を取得
        var pageCtaWrap = document.querySelector('.cta-wrap'); // 目標の要素を取得
        var sidebarTop = sidebar.getBoundingClientRect().top;
        window.addEventListener('scroll', function() {
          // スクロール位置を考慮して止めるための変数を用意
          if (imgSlideCtaWrap) {
            var stopScrollAt = imgSlideCtaWrap.getBoundingClientRect().top + window.pageYOffset - sidebar.offsetHeight - 112; 
            var stopScrollAtMobile = imgSlideCtaWrap.getBoundingClientRect().top + window.pageYOffset - sidebar.offsetHeight - 600 ; 
          }
          if (pageCtaWrap) {
            var stopScrollAt = pageCtaWrap.getBoundingClientRect().top + window.pageYOffset - sidebar.offsetHeight - 112; 
            var stopScrollAtMobile = pageCtaWrap.getBoundingClientRect().top + window.pageYOffset - sidebar.offsetHeight - 600 ; 
          }
        
          if (window.innerWidth > 768) { // PCサイズの場合
            if (window.pageYOffset > firstViewHeight && window.pageYOffset < stopScrollAt) {
              // ファーストビューを過ぎ、かつe_imgSlideCta_wrap手前までは固定
              sidebar.style.position = 'fixed';
              sidebar.style.top = '112px'; // 固定位置
            } else if (window.pageYOffset >= stopScrollAt) {
              // e_imgSlideCta_wrap手前で止める
              sidebar.style.position = 'absolute';
              sidebar.style.top = stopScrollAt - firstViewHeight + 'px';
            } else {
              // ファーストビュー内では通常の配置
              sidebar.style.position = 'relative';
              sidebar.style.top = 'auto';
            }
          }
    
          if (window.innerWidth <= 768) { // スマホサイズの場合
            if (window.pageYOffset >= stopScrollAtMobile) {
              // e_imgSlideCta_wrap手前で透明度を0にする
              parentElement.style.opacity = '0'; // 透明度を0にして非表示にする
            } else {
              // e_imgSlideCta_wrapに到達していない場合は透明度を戻す
              parentElement.style.opacity = '1'; // 透明度を通常に戻す
            }
          } else {
            // PCサイズの場合は透明度を通常に保つ（または処理をリセット）
            parentElement.style.opacity = '1'; 
          }
        });
      }

    }

    headingActive() {
            const sidebarItems = document.querySelectorAll('.side_hedhing_item');

            // セクションと対応するサイドバーのリンクのマッピングを動的に作成
            const sectionMap = {};

            if (sidebarItems.length > 0) {
                sidebarItems.forEach(item => {
                  const link = item.querySelector('a');
                  const href = link.getAttribute('href');
                  const sectionId = href.replace('#', '');
                  sectionMap[sectionId] = item; 
                });
            }

            function getRootMargin() {
              if (window.matchMedia("(max-width: 768px)").matches) {
                return '-20% 0px -80% 0px';  // スマホサイズの時のrootMargin
              } else {
                return '-10% 0px -90% 0px'; // PCサイズの時のrootMargin
              }
            }

            
            const observer = new IntersectionObserver((entries) => {
           entries.forEach(entry => {
             const sectionId = entry.target.id;
             const correspondingItem = sectionMap[sectionId];
             if (correspondingItem) {
                 if (entry.isIntersecting) {
    
                   correspondingItem.classList.add('is_active');
                 } else {
                   correspondingItem.classList.remove('is_active');
                 }
             }
           });
         }, 
         {
           threshold: 0,
           rootMargin: getRootMargin() ,
         });
         
         document.querySelectorAll('.sideWidth_main section').forEach(section => {
           observer.observe(section);
         });

         window.addEventListener('resize', () => {
           observer.disconnect();  // 既存の監視を解除
           document.querySelectorAll('.sideWidth_main section').forEach(section => {
             observer.observe(section);  // 新しいrootMarginで再監視
           });
         });
      }
    }
  
  export default SideBar;