/*
TOPページ
*/
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class Top {
	init() {
		document.addEventListener('DOMContentLoaded', () => {
			this.CompanyPalalax();
			this.ContactPalalax();
			this.ContactPalalax();
			this.iconAnimation();
			this.bgTextSlider();
			this.caseFetch();
		});
	}
	CompanyPalalax() {
		const body = document.body;
		const about = document.querySelector('.js_topbg_scroll');
		const bottombg = document.querySelector('.js_bottombg_scroll');
		const sections = document.querySelectorAll('.js_top_scrollSec');

		// グレー上グラデーション1番目
		if (about) {
			const main = body.querySelector('main');
			const background = body.querySelector('.js_background_gray_top');
			const onEnter = () => {
				gsap.to(background, {
					duration: 0.3,
					ease: "linear" ,
				});
				body.classList.add('__isGray');
				background.style.opacity =  1;
			}
			const onEnterBack = () => {
				gsap.to(background, {
					duration: 0.3,
				});
				body.classList.remove('__isGray');
				background.style.opacity =  0;
			}
			const onLeave = () => {
				gsap.to(background, {
					duration: 0.6,
				});
				body.classList.remove('__isGray');
				background.style.opacity =  0;
			}
			gsap.to(body, {
				scrollTrigger: {
					trigger: about,
					start: 'top center',
					end: 'top center',
					onEnter: () => onEnter(),
					onEnterBack: () => onEnterBack(),
				},
			});
			gsap.to(body, {
				scrollTrigger: {
					trigger: about,
					start: 'bottom center',
					end: 'bottom center',
					onEnter: () => onLeave(),
					onEnterBack: () => onEnter(),
				},
			});
		}

		// グレー下グラデーション2番目
		if (bottombg) {
			const main = body.querySelector('main');
			const background = body.querySelector('.js_background_gray_bottom');
			const onEnter = () => {
				gsap.to(background, {
					duration: 0.3,
					ease: "linear" ,
				});
				body.classList.add('__isGray');
				background.style.opacity =  1;
			}
			const onEnterBack = () => {
				gsap.to(background, {
					duration: 0.3,
					delay:0.5,
				});
				body.classList.remove('__isGray');
				background.style.opacity =  0;
			}
			const onLeave = () => {
				gsap.to(background, {
					duration: 0.6,
					delay:0.5,
				});
				body.classList.remove('__isGray');
				background.style.opacity =  0;
			}
			gsap.to(body, {
				scrollTrigger: {
					trigger: bottombg,
					start: 'top center',
					end: 'top center',
					onEnter: () => onEnter(),
					onEnterBack: () => onEnterBack(),
				},
			});
			gsap.to(body, {
				scrollTrigger: {
					trigger: bottombg,
					start: 'bottom+=600 center',
					end: 'bottom center',
					onEnter: () => onLeave(),
					onEnterBack: () => onEnter(),
					// markers: true,
				},
			});
		}
		// セクションパララックス
		if (sections?.length) {
			gsap.utils.toArray(sections).forEach((section) => {
				gsap.fromTo(section, {
					y: 64,
				}, {
					y: 0,
					scrollTrigger: {
						trigger: section,
						start: 'top bottom',
						end: 'bottom bottom',
						scrub: 0,
					}
				});
			});
		}
	}
	ContactPalalax() {
		const contact = document.querySelector(".section__contactBanner")
		const image = document.querySelector(".contactBanner_bg img")
		if(contact) {
			gsap.fromTo(image, {
				y: 25,
			},{
				y: -25,
				scrollTrigger: {
					trigger: contact,
					start: 'top bottom',
					end: 'bottom top',
					scrub: 0,
				}
			})
		}
	}
	iconAnimation() {
		window.addEventListener('load', function () {
			function getRootUrl() {
				const { protocol, hostname, port } = window.location;
				const portPart = port ? `:${port}` : ''; // ポートが存在する場合は追加
				return `${protocol}//${hostname}${portPart}/`;
			  }

			if (typeof gsap === 'undefined') {
			  console.error('GSAP library not loaded');
			  return;
			}

			try {

				function getServiceItems() {
					const screenWidth = window.innerWidth;

					if (screenWidth >= 1200 || screenWidth >= 920 ) { // デスクトップ
					  return [
						{ description: "口コミ改善\n管理ツール", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/Survey-prime.webp`, x: 100, y: 140, size: 130, imgWidth: 110, imgHeight: 21 },
						{ description: "インバウンド\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/nekkyo.webp`, x: 300, y: 20, size: 130, imgWidth: 79, imgHeight: 52 },
						{ description: "広告運用", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/ad-prime.webp`, x: 530, y: 30, size: 140, imgWidth: 106, imgHeight: 42 },
						{ description: "Google検索対策", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/suggest-prime.webp`, x: 240, y: 210, size: 160, imgWidth: 135, imgHeight: 35 },
						{ description: "MEO\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/meo-prime.webp`, x: 430, y: 160, size: 130, imgWidth: 120, imgHeight: 45 },
						{ description: "Googleストリートビュー\n撮影代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/street-prime.webp`, x: 730, y: 130, size: 170, imgWidth: 130, imgHeight: 35 },
						{ description: "Instagram\n運用支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/instack.webp`, x: 580, y: 220, size: 130, imgWidth: 100, imgHeight: 30 },
						{ description: "Instagram\n運用代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/sns-prime.webp`, x: 120, y: 330, size: 130, imgWidth: 104, imgHeight: 40 },
						{ description: "Webサイト制作\nサポート", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/hp-prime.webp`, x: 700, y: 330, size: 130, imgWidth: 95, imgHeight: 35 }
						// 他の項目も同様に追加
						];
					} else if (screenWidth >= 769) { // タブレット
					return [
						{ description: "口コミ改善\n管理ツール", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/Survey-prime.webp`, x: 80, y: 80, size: 120, imgWidth: 90, imgHeight: 16 },
						  { description: "インバウンド\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/nekkyo.webp`, x: 230, y: 10, size: 120, imgWidth: 64, imgHeight: 44 },
						  { description: "広告運用", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/ad-prime.webp`, x: 480, y: 30, size: 110, imgWidth: 106, imgHeight: 42 },
						  { description: "Google検索対策", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/suggest-prime.webp`, x: 190, y: 180, size: 160, imgWidth: 135, imgHeight: 35 },
						  { description: "MEO\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/meo-prime.webp`, x: 380, y: 160, size: 120, imgWidth: 120, imgHeight: 45 },
						  { description: "Googleストリートビュー\n撮影代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/street-prime.webp`, x: 650, y: 130, size: 170, imgWidth: 130, imgHeight: 35 },
						  { description: "Instagram\n運用支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/instack.webp`, x: 530, y: 220, size: 100, imgWidth: 110, imgHeight: 30 },
						  { description: "Instagram\n運用代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/sns-prime.webp`, x: 70, y: 330, size: 100, imgWidth: 104, imgHeight: 40 },
						  { description: "Webサイト制作\nサポート", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/hp-prime.webp`, x: 650, y: 330, size: 100, imgWidth: 95, imgHeight: 35 }

						// 他の項目も同様に追加
					  ];
					} else if (screenWidth >= 660) { // タブレット2
						return [
							{ description: "口コミ改善\n管理ツール", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/Survey-prime.webp`, x: 110, y: 16, size: 90, imgWidth: 90, imgHeight: 16 },
							  { description: "インバウンド\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/nekkyo.webp`, x: 243, y: 0, size: 90, imgWidth: 60, imgHeight: 32 },
							  { description: "広告運用", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/ad-prime.webp`, x: 443, y: 0, size: 90, imgWidth: 80, imgHeight: 32 },
							  { description: "Google検索対策", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/suggest-prime.webp`, x: 195, y: 100, size: 110, imgWidth: 125, imgHeight: 30 },
							  { description: "MEO\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/meo-prime.webp`, x: 342, y: 45, size: 90, imgWidth: 90, imgHeight: 34 },
							  { description: "Googleストリートビュー\n撮影代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/street-prime.webp`, x: 554, y: 10, size: 110, imgWidth: 100, imgHeight: 30 },
							  { description: "Instagram\n運用支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/instack.webp`, x: 471, y: 115, size: 90, imgWidth: 90, imgHeight: 28 },
							  { description: "Instagram\n運用代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/sns-prime.webp`, x: 50, y: 119, size: 100, imgWidth: 90, imgHeight: 36 },
							  { description: "Webサイト制作\nサポート", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/hp-prime.webp`, x: 608, y: 130, size: 90, imgWidth: 75, imgHeight: 28 }

							// 他の項目も同様に追加
						  ];
						} else if (screenWidth >= 430) { // タブレット2
							return [
								{ description: "口コミ改善\n管理ツール", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/Survey-prime.webp`, x: 49, y: 48, size: 60, imgWidth: 60, imgHeight: 10 },
								{ description: "インバウンド\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/nekkyo.webp`, x: 140, y: 20, size: 60, imgWidth: 37, imgHeight: 24 },
								{ description: "広告運用", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/ad-prime.webp`, x: 250, y: 22, size: 60, imgWidth: 49, imgHeight: 19 },
								{ description: "Google検索対策", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/suggest-prime.webp`, x: 93, y: 95, size: 75, imgWidth: 63, imgHeight: 16 },
								{ description: "MEO\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/meo-prime.webp`, x: 190, y: 70, size: 60, imgWidth: 51, imgHeight: 19 },
								{ description: "Googleストリートビュー\n撮影代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/street-prime.webp`, x: 334, y: 57, size: 80, imgWidth: 61, imgHeight: 16 },
								{ description: "Instagram\n運用支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/instack.webp`, x: 265, y: 108, size: 60, imgWidth: 50, imgHeight: 14 },
								{ description: "Instagram\n運用代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/sns-prime.webp`, x: 52, y: 160, size: 60, imgWidth: 49, imgHeight: 19 },
								{ description: "Webサイト制作\nサポート", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/hp-prime.webp`, x: 316, y: 161, size: 60, imgWidth: 43, imgHeight: 14 }

								// 他の項目も同様に追加
							  ];
							} else { // スマートフォン
						return [
							{ description: "口コミ改善\n管理ツール", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/Survey-prime.webp`, x: 19, y: 48, size: 60, imgWidth: 60, imgHeight: 10 },
							{ description: "インバウンド\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/nekkyo.webp`, x: 100, y: 20, size: 60, imgWidth: 37, imgHeight: 24 },
							{ description: "広告運用", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/ad-prime.webp`, x: 220, y: 22, size: 60, imgWidth: 49, imgHeight: 19 },
							{ description: "Google検索対策", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/suggest-prime.webp`, x: 73, y: 95, size: 75, imgWidth: 63, imgHeight: 16 },
							{ description: "MEO\n対策支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/meo-prime.webp`, x: 160, y: 70, size: 60, imgWidth: 51, imgHeight: 19 },
							{ description: "Googleストリートビュー\n撮影代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/street-prime.webp`, x: 304, y: 57, size: 80, imgWidth: 61, imgHeight: 16 },
							{ description: "Instagram\n運用支援", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/instack.webp`, x: 235, y: 108, size: 60, imgWidth: 50, imgHeight: 14 },
							{ description: "Instagram\n運用代行", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/sns-prime.webp`, x: 22, y: 160, size: 60, imgWidth: 49, imgHeight: 19 },
							{ description: "Webサイト制作\nサポート", icon: `${getRootUrl()}/wp-content/themes/delta_theme/dist/images/logo/hp-prime.webp`, x: 286, y: 161, size: 60, imgWidth: 43, imgHeight: 14 }
						];
					  }
				  }
				const serviceItems = getServiceItems();
				const container = document.getElementById('animation-container');
				const ripples = document.querySelectorAll('.ripple');



				if (!container) {
					return; // containerがない場合は処理を中止
				  }
			  // Create service items
			  const service_wrapper = document.createElement('div');
			  service_wrapper.className = 'service-items-wrapper';
			  serviceItems.forEach((item, index) => {
				  const element = document.createElement('div');
				  const className = `item-${index + 1}`;
				  const descriptionWithBreaks = item.description.replace(/\n/g, "<br>");
				  element.className = `service-item ${className}`;
				  element.innerHTML = `
				  <img src="${item.icon}" alt="${item.description}" width="${item.imgWidth}" height="${item.imgHeight}">
				  <p>${descriptionWithBreaks}</p>
				`;
				service_wrapper.appendChild(element);

				gsap.set(element, { x: item.x, y: item.y, width: item.size, height: item.size });
			  });

			  container.appendChild(service_wrapper);


			  // Ripple animation
			  function animateRipples() {
				ripples.forEach((ripple, index) => {
				  gsap.to(ripple, {
					scale: 1,
					opacity: 0,
					duration: 6,
					ease: "power1.out",
					delay: index * 1.5,
					repeat: -1,
					repeatDelay: 0,
					onRepeat: () => {
					  gsap.set(ripple, { scale: 0, opacity: 0.3 });
					}
				  });
				});
			  }

			  // Service items animation
			  function animateServiceItems() {
				const serviceItemsArray = Array.from(container.querySelectorAll('.service-item'));
				if (serviceItemsArray.length === 0) {
					console.error('No service items found');
					return;
				}
				gsap.utils.shuffle(serviceItemsArray);

				// Divide items into 3 groups
				const groupSize = Math.ceil(serviceItemsArray.length / 3);
				for (let i = 0; i < 3; i++) {
				  const group = serviceItemsArray.slice(i * groupSize, (i + 1) * groupSize);
				  gsap.to(group, {
					  opacity: 1,
					  scale: 1,
					  duration: 1,
					  ease: "elastic.out(1, 0.5)",
					  stagger: 0.1,
					  delay: i * 0.5,
				  });
				}
			  }

			  // Start animations
			  animateRipples();
			  animateServiceItems();

			} catch (error) {
			  console.error('Error in animation script:', error);
			}
		  });
	}
	bgTextSlider() {
		const element = document.querySelectorAll('.js_top_bgtext_roop');
		// 要素が存在する場合にのみアニメーションを発火
		if (element) {
		  // GSAP Timelineの作成
		  const tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });

		  tl.fromTo(
			element,
			{ x: `0%` },  // 右端からスタート
			{
			  x: `-100%`,    // 左端に移動
			  duration: 40,      // アニメーションの持続時間
			  ease: "linear"     // スムーズに移動
			}
		  );
		}
	}
	caseFetch() {
		const target = document.querySelector('.js_top_caseFetch');
		if(target) {
			$.ajax({
				url: 'https://media.meo-taisaku.com/wp-json/my-custom/v1/filtered-posts',
				// url: 'https://lulls.work/makesuku/wp-json/my-custom/v1/filtered-posts',
				method: 'GET',
				success: function(response) {
					// console.log(response);
					const posts = response.posts;
					setTimeout(()=> {
						if (posts.length > 0) {
							$('.js_top_caseFetch_container').empty(); // コンテナをクリア
							let count = 0;
							$.each(posts, (index, post) => { // アロー関数を使用
								count++;
								if(count <= 3) {
									$('.js_top_caseFetch .js_top_caseFetch_container').append(`
										<a href="${post.url}" target="_blank" class="js_skeleton_hidden p_case_case_article_item" style="opacity: 0.25;">
											<div class="p_case_case_article_item_top">
												<div class="p_case_case_article_item_img">
													<img src="${post.thumbnail}" alt="${post.title}">
													<span class="p_case_case_article_item_img_circle"></span>
												</div>
												<p class="p_case_case_article_item_label t_title_large">${post.title}</p>
											</div>
											<div class="p_case_case_article_item_textarea">
												<ul class="p_case_case_article_item_category_list">
													<li class="p_case_case_article_item_category_item t_body_small">
														<span class="p_case_case_article_item_category_label">業種：</span>
														<span class="p_case_case_article_item_category_desc">${post.indastry_name || ''}</span>
													</li>
													<li class="p_case_case_article_item_category_item t_body_small">
														<span class="p_case_case_article_item_category_label">規模：</span>
														<span class="p_case_case_article_item_category_desc">${post.scale_name || ''}</span>
													</li>
													<li class="p_case_case_article_item_category_item t_body_small">
														<span class="p_case_case_article_item_category_label">サービス：</span>
														<span class="p_case_case_article_item_category_desc">${post.service_name || ''}</span>
													</li>
												</ul>
											</div>
										</a>
									`);
								}
							});
							$('.js_top_caseFetch_container').children('.js_skeleton_hidden').animate({
								opacity: 1
							}, 300);
						} else {
							$('.js_top_caseFetch').remove();
						}
					},300);
				},
				error: function(xhr, status, error) {
					console.error('APIエラー:', error);
					$('.js_top_caseFetch_container').empty(); // コンテナをクリア
					$('.js_top_caseFetch_container').append('<p>読み込みに失敗しました。再度ページを読み込んでください。</p>');
				}
			});
		}
	}
}

export default Top;
