class LinkIcon {
	init() {
		document.addEventListener('DOMContentLoaded', () => {
			this.BlankIcon();
		});
	}
	BlankIcon() {
		const externalLinks = document.querySelectorAll('a.link_text[target="_blank"],a.nav-link[target="_blank"],a[target="_blank"]:not([class])');
		if(externalLinks) {
			externalLinks.forEach(link => {
			const span = document.createElement('span');
			span.classList.add('svg-icon-container');
			  // SVGアイコンを生成
			  const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
			  svgIcon.setAttribute('width', '16');
			  svgIcon.setAttribute('height', '16');
			  svgIcon.setAttribute('viewBox', '0 0 16 16');
			  svgIcon.setAttribute('fill', 'none');
			  svgIcon.setAttribute('stroke', 'currentColor');
			  svgIcon.setAttribute('stroke-width', '0');
			  
			  svgIcon.innerHTML = `<path d="M9.66667 0.25V1.91667H12.6583L4.46667 10.1083L5.64167 11.2833L13.8333 3.09167V6.08333H15.5V0.25M13.8333 13.5833H2.16667V1.91667H8V0.25H2.16667C1.24167 0.25 0.5 1 0.5 1.91667V13.5833C0.5 14.0254 0.675595 14.4493 0.988155 14.7618C1.30072 15.0744 1.72464 15.25 2.16667 15.25H13.8333C14.2754 15.25 14.6993 15.0744 15.0118 14.7618C15.3244 14.4493 15.5 14.0254 15.5 13.5833V7.75H13.8333V13.5833Z" fill="currentColor"/>`;
			
			  // アイコンをリンク内に追加（テキストの後に追加）
			  link.appendChild(svgIcon);
	
				  // spanタグにSVGアイコンを追加
				  span.appendChild(svgIcon);
	
				  // SVGアイコンをリンク内に追加（テキストの後に追加）
				  link.appendChild(span);
			
			  // アクセシビリティ対応: リンクに外部リンクであることを示すaria-labelを追加
			  link.setAttribute('aria-label', link.textContent.trim() + ' (別タブで開きます)');
			});
		}
	}
}

export default LinkIcon;