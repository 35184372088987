// スクロールバーを無視したVwの計算
class Vw {
	init() {
		document.addEventListener('DOMContentLoaded', () => {
			this.setVw();
			this.reSize();
		});
	}
	setVw() {
		const vw = document.documentElement.clientWidth / 100;
		document.documentElement.style.setProperty('--vw', `${vw}px`);
	}
	reSize() {
		window.addEventListener('resize', ()=> {
			this.setVw();
		});
	}
}

export default Vw;
