import gsap from 'gsap';

class ScrollingColumns {
    constructor() {
        this.containerId = 'animated-columns';
        this.container = null;
        this.columnWidth = 0;
        this.columnHeight = 0;
        this.imageHeight = 0;
        this.imageGap = 0;
        this.imagesPerColumn = 0;
        this.imageCount = 15;
        this.imageBasePath = '';
        this.mainText = 'Webマーケティングの力で、\n「知らない」をなくし、\n「愛される」店舗をふやす。';
        this.subText = 'トリニアスは、\n実店舗を持つ企業向けに、\nデジタルマーケティングを活用した\n集客支援サービスを提供しています。';
        this.isMobile = false;
    }

    updateDimensions() {
        if (!this.container) return;

        this.isMobile = window.innerWidth <= 768;
        const containerWidth = window.innerWidth;
        const containerHeight = this.isMobile ? containerWidth * 0.893229 : containerWidth * 0.48888;

        this.container.style.width = 'auto';
        this.container.style.height = this.isMobile ? '89.3229vw' : '48.8888vw';

        const columnCount = this.isMobile ? 3 : 5;
        const gapRatio = this.isMobile ? 16 / 768 : 24 / 1440;

        this.columnWidth = (containerWidth / columnCount) - (gapRatio * containerWidth * (columnCount - 1) / columnCount);
        this.imageGap = containerWidth * gapRatio;

        this.columnHeight = containerHeight;
        this.imageHeight = this.columnWidth * 4/3;
        this.imagesPerColumn = Math.ceil(this.columnHeight / (this.imageHeight + this.imageGap)) + 1;
    }

    animateColumns() {
        if (!this.container) return;

        const columns = this.container.querySelectorAll('.column');
        const duration = 60;

        columns.forEach((column, index) => {
            const scrollContent = column.querySelector('.scroll-content');
            const direction = index % 2 === 0 ? 1 : -1;
            const contentHeight = scrollContent.offsetHeight;
            const visibleHeight = this.columnHeight;
            const distance = contentHeight / 3;

            gsap.killTweensOf(scrollContent);

            if (direction === 1) {
                gsap.set(scrollContent, { y: -distance });
            } else {
                gsap.set(scrollContent, { y: -(contentHeight - visibleHeight - distance) });
            }

            gsap.to(scrollContent, {
                y: direction === 1 ? -(distance * 2) : -(contentHeight - visibleHeight - distance * 2),
                ease: 'none',
                repeat: -1,
                duration: duration,
                modifiers: {
                    y: gsap.utils.unitize(y => {
                        const currentY = parseFloat(y);
                        if (direction === 1) {
                            return -distance + (currentY % distance);
                        } else {
                            return -(contentHeight - visibleHeight - distance) + (currentY % distance);
                        }
                    })
                }
            });
        });
    }

    init() {
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => this.initializeComponent());
        } else {
            this.initializeComponent();
        }

        this.updateText();
    }

    initializeComponent() {
        const scrollingColumnsData = window.scrollingColumnsData || {};
        this.imageBasePath = scrollingColumnsData.imageBasePath || this.getDefaultImageBasePath();
        this.imageCount = scrollingColumnsData.imageCount || this.imageCount;
        this.containerId = scrollingColumnsData.containerId || this.containerId;
        this.mainText = scrollingColumnsData.mainText || this.mainText;
        this.subText = scrollingColumnsData.subText || this.subText;

        this.container = document.getElementById(this.containerId);
        if (!this.container) return;

        this.createStructure();
        this.updateDimensions();
        this.createColumns();
        this.animateColumns();
        this.updateText();
        this.textContainer = this.container.querySelector('.scrolling-columns-text');

        window.addEventListener('resize', () => {
            this.updateDimensions();
            this.createColumns();
            this.animateColumns();
        });
    }

    createColumns() {
        if (!this.columnsContainer) return;

        this.columnsContainer.innerHTML = '';
        const columnCount = this.isMobile ? 3 : 5;

        for (let i = 0; i < columnCount; i++) {
            const column = document.createElement('div');
            column.className = 'column';
            column.style.width = `${this.columnWidth}px`;
            column.style.marginRight = `${this.imageGap}px`;
            const scrollContent = document.createElement('div');
            scrollContent.className = 'scroll-content';

            const startImage = i * 3 + 1;

            for (let j = 0; j < this.imagesPerColumn * 3; j++) {
                const imgNum = ((j % 3) + startImage - 1) % this.imageCount + 1;
                const img = document.createElement('img');
                if (this.imageBasePath) {
                    img.src = `${this.imageBasePath}/image${imgNum}.webp`;
                } else {
                    img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                }
                img.alt = `Image ${imgNum}`;
                img.style.width = '100%';
                img.style.height = 'auto';
                img.style.marginBottom = `${this.imageGap}px`;
                scrollContent.appendChild(img);
            }

            column.appendChild(scrollContent);
            this.columnsContainer.appendChild(column);
        }
    }

    createStructure() {
        this.container.innerHTML = `
            <div class="scrolling-columns-content"></div>
            <div class="scrolling-columns-overlay"></div>
            <div class="scrolling-columns-text">
                <h1></h1>
                <p class="t_body_medium__medium"></p>
            </div>
        `;
        this.columnsContainer = this.container.querySelector('.scrolling-columns-content');
        this.textContainer = this.container.querySelector('.scrolling-columns-text');
    }

    setText(mainText, subText) {
        this.mainText = mainText || this.mainText;
        this.subText = subText || this.subText;
        this.updateText();
    }

    updateText() {
        if (!this.textContainer) return;

        const mainTextElement = this.textContainer.querySelector('h1');
        const subTextElement = this.textContainer.querySelector('p');

        if (mainTextElement) {
            mainTextElement.innerHTML = this.mainText.replace(/\n/g, '<br>');
        }

        if (subTextElement) {
            subTextElement.innerHTML = this.subText.replace(/\n/g, '<br>');
        }
    }

    getDefaultImageBasePath() {
        const scriptTag = document.querySelector('script[src*="/wp-content/themes/"]');
        if (scriptTag) {
            const srcPath = scriptTag.getAttribute('src');
            const themeUrl = srcPath.split('/wp-content/themes/')[0] + '/wp-content/themes/';
            return themeUrl + 'delta_theme/dist/images/top/fv';
        }
        return '';
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const scrollingColumns = new ScrollingColumns();
    scrollingColumns.init();
});

export default ScrollingColumns;
