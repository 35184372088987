import Modal from './Modal';
import Accordion from './Accordion';
import Header from './Header';
import SliderSwiper from './SwiperComponents';
import Animation from './Animation';
import ScrollingColumns from './ScrollingColumns';
import ImgSlideCta from './ImgSlideCta';
import SideBar from './SideBar';

// import SliderSwiper from './SwiperComponents';

class Components {
  constructor() {
    this.modal = new Modal();
    this.accordion = new Accordion();
    this.header = new Header();
    this.sliderSwiper = new SliderSwiper();
    this.animation = new Animation();
    this.scrollingColumns = new ScrollingColumns();
    this.ImgSlideCta = new ImgSlideCta();
    this.SideBar = new SideBar();
  }
  init() {
    this.modal.init();
    this.accordion.init();
    this.header.init();
    this.sliderSwiper.init();
    this.animation.init();
    this.scrollingColumns.init();
    this.ImgSlideCta.init();
    this.SideBar.init();
  }
}
export default Components;
