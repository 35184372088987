import Swiper from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';

import { EffectFade, Autoplay,Pagination} from 'swiper/modules';
Swiper.use([Autoplay, Pagination,EffectFade]);

class SliderSwiper {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
					this.mySwiper = document.querySelectorAll('.js_full_autoSlide');
        });
    }
    init() {
			document.addEventListener('DOMContentLoaded', () => {
				this.slide();
			});
    }
    slide() {
        var mySwiper = new Swiper('.p_company_philosophy_swiper-container', {
            slidesPerView: 'auto',
            spaceBetween: 40,  // スライド間の余白
            loop: true,  // ループさせる
            speed: 8000, 
            autoplay: {
              delay: 0,
              disableOnInteraction: false,
            },
            loopedSlides: 10,
            allowTouchMove: false,
        });
        // const cSwipers02 = this.cSwipers02;
        // if (cSwipers02.length) {
        //     cSwipers02.forEach((swiperContainer) => {
        //         new Swiper(swiperContainer, {
        //             loop: true,
        //             effect: 'fade',
        //             speed: 2000,
        //             autoplay: {
        //                 delay: 5000,
        //                 disableOnInteraction: false,
        //             },
        //             modules: [EffectFade, Autoplay],
        //         });
        //     });
        // }
    }

    
}

export default SliderSwiper;
