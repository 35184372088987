
// ロード時のbody表示用のアニメーション

class Roaded {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.body = document.body;
        });
    }
    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.Roaded();
        });
    }
    Roaded() {
        const body =this.body;
        body.style.opacity = 0;
        window.addEventListener('load', () => {
            body.style.transition = "0.7s";
            body.style.opacity = 1;
        });
    }
}
  
export default Roaded;