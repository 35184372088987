import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class ImgSlideCta {
    init() {
		document.addEventListener('DOMContentLoaded', () => {
			this.recruitCtaImage();
		});
	}

    recruitCtaImage() {

        const imgWrap = document.querySelector('.js-imgSlideCta_img');
        const images = document.querySelectorAll('.e_imgSlideCta_img');
        
        if (images.length === 0) {
          return; // 画像がない場合は処理を終了
        }

        const totalImages = images.length;
        const totalHeight = images[0].clientHeight * totalImages;

        // 画像が途切れずに連続して流れるようにする設定
        
         imgWrap.innerHTML += imgWrap.innerHTML;
         gsap.to(imgWrap, {
           y: -totalHeight,
           duration: totalImages * 20, // 各画像が3秒かけて流れる設定
           repeat: -1, // 無限ループ
           ease: "none",
         });

    }
  }
  
  export default ImgSlideCta;