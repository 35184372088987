// gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class Header {
	// constructor() {
	// 	this.enterFlag = false;
	// }
	init() {
		document.addEventListener("DOMContentLoaded", () => {
			// コンポーネント化
			// スムーススクロール
			const headerHeight = document.querySelector('header').offsetHeight;
			document.querySelectorAll('a[href^="#"]')?.forEach(anchor => {
				anchor.addEventListener('click', function (e) {
					e.preventDefault();
					const href = anchor.getAttribute('href');
					const target = document.getElementById(href.replace('#', ''));
					const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
					window.scrollTo({
							top: targetPosition,
							behavior: 'smooth'
					});
				});
			});

			this.scrollTrigger();
			this.humburger();
			this.hamburgerSp();
		});
	}

	humburger() {
  		let lastScrollTop = 0;
  		const headerWrapper = document.getElementById('header-wrapper');
  		const scrollThreshold = 300;

  		function handleScroll() {
  		  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		
  		  if (scrollTop > scrollThreshold) {
  		    if (scrollTop > lastScrollTop) {
  		      // 下にスクロール
  		      headerWrapper.style.opacity = '0';
  		      headerWrapper.style.visibility = 'hidden';
  		    } else {
  		      // 上にスクロール
  		      headerWrapper.style.opacity = '1';
  		      headerWrapper.style.visibility = 'visible';
  		    }
  		  } else {
  		    // トップ付近
  		    headerWrapper.style.opacity = '1';
  		    headerWrapper.style.visibility = 'visible';
  		  }
	  
  		  lastScrollTop = scrollTop;
  		}
	
  		// スロットリング関数
  		function throttle(func, limit) {
  		  let inThrottle;
  		  return function() {
  		    const args = arguments;
  		    const context = this;
  		    if (!inThrottle) {
  		      func.apply(context, args);
  		      inThrottle = true;
  		      setTimeout(() => inThrottle = false, limit);
  		    }
  		  }
  		}
	
  		// スロットリングを適用したスクロールイベントリスナー
  		window.addEventListener('scroll', throttle(handleScroll, 100));
	
  		const servicesLink = document.getElementById('services-link');
  		const servicesDropdown = document.getElementById('services-dropdown');
  		const overlay = document.getElementById('overlay');
  		let dropdownTimeout;
	
  		function showDropdown() {
  		  clearTimeout(dropdownTimeout);
  		  servicesDropdown.classList.add('active');
  		  overlay.style.opacity = '1';
  		  overlay.style.visibility = 'visible';
  		}
	
  		function hideDropdown() {
  		  dropdownTimeout = setTimeout(() => {
  		    servicesDropdown.classList.remove('active');
  		    overlay.style.opacity = '0';
  		    overlay.style.visibility = 'hidden';
  		  }, 300); // トランジション時間に合わせて調整
  		}
	
  		servicesLink.addEventListener('mouseenter', showDropdown);
  		headerWrapper.addEventListener('mouseleave', hideDropdown);
  		servicesDropdown.addEventListener('mouseenter', () => clearTimeout(dropdownTimeout));
  		servicesDropdown.addEventListener('mouseleave', hideDropdown);
			}
		
  hamburgerSp(){
	const hamburger = document.querySelector('.hamburger');
	const menu = document.querySelector('.menu');
	const menuOverlay = document.querySelector('.menu_overlay');
	const body = document.body;

	function openMenu() {
		hamburger.classList.add('active');
		menu.classList.add('active');
		menuOverlay.classList.add('active');
		body.classList.add('menu-open');
	}

	function closeMenu() {
		hamburger.classList.remove('active');
		menu.classList.remove('active');
		menuOverlay.classList.remove('active');
		body.classList.remove('menu-open');
	}

	hamburger.addEventListener('click', () => {
		if (menu.classList.contains('active')) {
			closeMenu();
		} else {
			openMenu();
		}
	});

	menuOverlay.addEventListener('click', closeMenu);

	// メニュー内のリンクをクリックしたときにもメニューを閉じる
	const menuLinks = menu.querySelectorAll('a');
	menuLinks.forEach(link => {
		link.addEventListener('click', (e) => {
			// 内部リンクの場合のみ preventDefault を使う
			if (link.getAttribute('href').startsWith("#")) {
				e.preventDefault();
				const href = link.getAttribute('href');
				const target = document.getElementById(href.replace('#', ''));
				const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
				window.scrollTo({
					top: targetPosition,
					behavior: 'smooth'
				});
			}
			closeMenu();
		});
	});
	}

	scrollTrigger() {
		const header = document.querySelector('.js_header_scroll');
		const target = document.querySelector('.js_header_scroll_target');
		if(target && header) {
			const targetTop = target.getBoundingClientRect().bottom + window.pageYOffset + header.offsetHeight - 10;
			const positionFlag = window.pageYOffset < targetTop ? true: false;
			header.style.backgroundColor = positionFlag ? "rgba(248, 248, 248, 0)" : "rgba(248, 248, 248, 1)";
			gsap.to(header, {
				scrollTrigger: {
					trigger: target,
					start: `bottom-=${header.offsetHeight - 10} top`,
					end: "bottom bottom",
					onEnter: () => onEnter(),
					onEnterBack: () => onEnterBack(),
				},
			});
			const onEnter = () => {
				this.enterFlag = true;
				header.classList.add('__isBlack');
				gsap.to(header,{
					backgroundColor: "rgba(248, 248, 248, 1)",
					color: "rgba(43, 43, 43, 1)",
					duration: 0.3,
				});
			}
			const onEnterBack = () => {
				this.enterFlag = false;
				header.classList.remove('__isBlack');
				gsap.to(header, {
					backgroundColor: "rgba(248, 248, 248, 0)",
					color: "rgba(255, 255, 255, 1)",
					duration: 0.3,
				});
			}
		}
	}
}
export default Header;
