import Top from "./Top";
import Service from "./Service";
import Case from "./Case";
class Pages {
  constructor() {
    this.top = new Top();
    this.service = new Service();
    this.case = new Case();
  }
  init() {
    this.top.init();
    this.service.init();
    this.case.init();
  }
}
export default Pages;
